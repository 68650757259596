html {
    scroll-behavior: smooth;
}

.Navbar {
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 1vmin);
    background-color: black;
    height: 100px;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 100 !important;
}

.Navbar-logo {
    margin-right: 50px !important;
}

.App-header {
  background-color: white;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#Whom-Title {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.About-Card {
    width: 90%;
    margin-left: 5% !important;
    text-align: center; 
    font-size: calc(10px + 1vmin);
    position: relative;
}

.About-Icon {
    margin-top: 30px;
    width: 10%;
    height: 10%;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.Parkburger-Jumbo {
    position: absolute;
    margin-top: -20% !important;
    border-top: 1px solid grey !important;
    z-index: 1 !important;
}

.Tenant-Icon {
    position: relative;
    margin-top: -10%;
    margin-left: 26%;
    z-index: 2 !important;
    text-align: center;    
    font-size: calc(10px + 4vmin);
}

.Awards-Icon {
    position: absolute;
    margin-top: -8%;
    margin-left: 28%;
    z-index: 2 !important;
    text-align: center;
    font-size: calc(10px + 4vmin);
}

.Rob-Image {
    position: relative;
    margin-top: 8%;
    margin-left: 23%;
    text-align: center;
    font-size: calc(20px + 2vmin);
}

.Nikki-Image {
    position: relative;
    margin-top: 8%;
    margin-left: 6%;
    text-align: center;
    font-size: calc(20px + 2vmin);
}

.Noah-Image {
    position: relative;
    margin-top: 1%;
    margin-left: 38%;
    text-align: center;
    font-size: calc(20px + 2vmin);
}

.Image-Overlay {
    background: black;
    overflow: hidden;
}

.Property-Image {
    object-fit: cover;
    opacity: 1;
    transition: all 0.3s;
    z-index: 3 !important;
    position: relative;
}

.Property-Image:hover {
    opacity: 0.4;
    z-index: 1 !important;
}

.Location-Description {
    margin-top:30%;
    color: white;
    z-index: 0 !important;
}

.Mayor-Award-Jumbo {
    margin-top: 2% !important;
    border-top: 1px solid grey !important;
    z-index: 1 !important;
    width: 100%;
}
